import { createSlice } from '@reduxjs/toolkit';
import { GlobalState } from '../../@types/globalState';
import State from 'enums/State';
import { stat } from 'fs';

// ----------------------------------------------------------------------

const initialState: GlobalState = {
  isLoading: false,
  error: false,
  bookingStatus: undefined,
  errorReason: undefined,
  state: State.loading,
  cable: undefined,
};

const slice = createSlice({
  name: 'globalState',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setBookingStatus(state, action) {
      state.bookingStatus = action.payload
    },

    setErrorReason(state, action) {
      state.errorReason = action.payload
    },
    
    setState(state, action) {
        state.state = action.payload
    }, 

    setCable(state, action){
      state.cable = action.payload
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setState, setBookingStatus, setErrorReason, setCable } = slice.actions;

import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../@types/user';

// ----------------------------------------------------------------------

const initialState: { user: User | undefined, authToken: string | undefined, expiresAt: Date | undefined } = {
  user: undefined,
  authToken: undefined,
  expiresAt: undefined
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    
    setUser(state, action) {
      state.user = action.payload
    },

    setAuthToken(state, action) {
      if(Boolean(action.payload)) {
        state.expiresAt = undefined
      } else {
        state.expiresAt = new Date(new Date().getTime()+(60*1000));
      }
      
      state.authToken = action.payload
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setUser, setAuthToken } = slice.actions;
